import Vue from 'vue';
import {
  Overlay,
  Icon,
  Toast,
  Popover,
  Loading,
  Button,
  Popup
} from 'vant';

Vue.use(Overlay);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Popover);
Vue.use(Loading);
Vue.use(Button);
