import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || {},
    token: localStorage.getItem('token') || ''
  },
  getters: {
  },
  mutations: {
    setUser(state, data) {
      state.user = data
      localStorage.setItem('user', JSON.stringify(data))
    },
    setToken(state, data) {
      state.token = data
      localStorage.setItem('token', state.token)
    }
  },
  actions: {
  },
  modules: {
  }
})
