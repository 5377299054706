export const getRandomString = (len) => {
  len = len || 6;
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = $chars.length;
  var pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

export const getString = () => {
  if (window.location.search) {
    const search = window.location.search
    const array = search.replace('?', '').split('&')
    array.forEach(item => {
      const temp = item.split('=')
      temp[1] = temp[1].replace('/', '')
      if (temp[0] === 'invited_id' && localStorage.getItem('invited_id')) {
        if (temp[1] == localStorage.getItem('user_id')) {
          console.log(1111)
        } else {
          localStorage.setItem(temp[0], temp[1])
        }
      } else {
        localStorage.setItem(temp[0], temp[1])
      }
    })
  }
}

export const randomString = (e) => {
	e = e || 6;
	var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
		a = t.length,
		n = "";
	for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
	return n
}

export const phoneEncode = (phone) => {
	const prefix = randomString()
	const suffix = randomString()
	const number = typeof phone === 'number' ? phone : Number(phone)
	const base64 = `${prefix}${number.toString(16)}${suffix}`
	return base64
}