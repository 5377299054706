import axios from 'axios'
// 'http://testshu.cn.vc/'
// 'http://nftfamily.local.com/'
export const baseURL = 'https://api.shucang123.com/'

const http = axios.create({
  baseURL: baseURL,
  timeout: 10000
})

http.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
  }
  return config
}, err => {
  Promise.reject(err)
  console.log(err)
})

http.interceptors.response.use(res => {
  if (res.data.code === 200) {
    return res.data
  } else {
    return Promise.reject(res.data)
  }
}, err => {
  console.log(err)
  return Promise.reject({
    message: err
  })
})

export default http
