import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Index from '../views/Index.vue'
import {
  getString
} from "../plugins/tool";


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: Index
  }, {
    path: '/CreateWorld',
    name: 'CreateWorld',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/CreateWorld.vue')
  }, {
    path: '/CreateWorldRule',
    name: 'CreateWorldRule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/CreateWorldRule.vue')
  }

]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  const invited_id = localStorage.getItem('invited_id')
  const user_id = localStorage.getItem('user_id')
  const origin = window.location.origin
  const hash = window.location.hash
  const pathname = window.location.pathname
  const search = window.location.search
  let array
  search ? array = search.replace('?', '').split('&') : ''
  let urlId
  if (array) {
    array.forEach(item => {
      const temp = item.split('=')
      temp[1] = temp[1].replace('/', '')
      if (temp[0] === 'invited_id') {
        urlId = temp[1]
        if (!invited_id) {
          localStorage.setItem('invited_id', temp[1])
        }
        if (invited_id && user_id && urlId != user_id) {
          localStorage.setItem('invited_id', temp[1])
        }
      } else {
        localStorage.setItem(temp[0], temp[1])
      }
    })
  }
  if (localStorage.getItem('token') && !search) {
    window.location.href = `${origin}${pathname}?invited_id=${user_id}${hash}`
  } else if (localStorage.getItem('token') && search && urlId != user_id) {
    window.location.href = `${origin}${pathname}?invited_id=${user_id}${hash}`
  } else {
    next()
  }
})

export default router
