<template>
  <div class="index">
    <!-- <button @click="clear">清除</button> -->
    <header class="flex between ignore-header" :style="{ 'background': scrollTop > 10 ? '#000000' : '' }">
      <img src="https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Flogo.png" class="logo" />
      <van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect" theme="dark"
        placement="bottom-end" className="popover">
        <template #reference>
          <van-icon name="wap-nav" color="#ffffff" size="28" />
        </template>
      </van-popover>
    </header>
    <div class="up">
      <section class="video">
        <div class="video-tip2" v-if="liveList.length && liveList[0].type == 1">6月14日 ，线上视频直播</div>
        <!-- <div class="video-tip f-11" v-if="!overPoster">观看人数：{{ audience }}</div> -->
        <div v-if="overPoster" style="height: 100%;">
          <img :src="overPoster" style="width: 100%;height: 100%;" />
          <div class="play" @click="goTo">
            <div class="text-center">
              <van-icon name="play-circle" color="#ffffff" size="34" />
            </div>
            <div class="f-11">点击观看直播回放</div>
          </div>
        </div>
        <video id="myvideo" class="video-js vjs-default-skin vjs-big-play-centered" v-else autoplay playsinline controls
          x5-video-player-type="h5" preload="auto" x5-video-player-fullscreen="true" :poster="poster">
          <source :src="liveSrc" type="application/x-mpegURL" />
          <!-- <source src="https://cn-scya-ct-01-08.bilivideo.com/live-bvc/424489/live_318849710_82287118.m3u8" type="application/x-mpegURL"/> -->
        </video>
      </section>
      <div class="introduce">
        <div>
          <img src="https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2FBACKGROUND.png" class="background" />
          <div class="f-20">背景介绍</div>
        </div>
        <div class="background-wrap">
          <div class="background-wrap-des">

            <p class="f-11">
              2022年是元宇宙、数字藏品行业蓬勃发展，烈火燎原的一年。我们目睹了“万物皆可元宇宙”，无数资本、品牌、机构、文化IP纷纷入场探索，也目睹了行业外以“泡沫”，“炒作”等字眼试图为元宇宙、数字藏品行业作注。
            </p>
            <p class="f-11">
              在无限的火热与争议中，我们正在见证中国元宇宙、数字藏品赛道诞生以来的最伟大变革落地。
            </p>
            <p class="f-11">
              本次线上峰会，将邀请学术机构、投资机构、巨头公司、头部品牌IP、底层技术团队、头部数字藏品平台、艺术创作者等行业全生态参与方，打造一场行业“去芜存菁”，对现状以及未来探讨的高规格行业盛会。
            </p>
          </div>

          <div class="activies">
            <div class="activies" :style="`background: url(${item.list_img}) no-repeat;`"
              v-for="(item, index) in liveList" @click="onGoto(item, index)" :key="index">
              <div class="f-15 m-b-15">{{ item.title }}</div>
              <div class="flex between f-12">
                <span class="grey">{{ item.show_time }}</span>
                <template v-if="item.type == 1">
                  <span v-if="item.subscrible" class="reserve-btn f-11">已预约</span>
                  <span @click="readyReserve(item.id)" v-else class="reserve-btn f-11">直播预约</span>
                </template>
                <template v-else-if="item.type == 4">
                  <div class="button review f-11">{{ item.typeName }}</div>
                </template>
                <template v-else>
                  <span :class="item.type == 2 ? 'ing' : ''">{{ item.typeName }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="fire">
      <img src="https://zhuzi.shucang123.cn.vc/uploads/images/0610/16548556870003090.gif" style="width: 100%;" />
    </div>
    <div class="down">

      <div class="m-b-15">
        <img src="https://zhuzi.shucang123.cn.vc/uploads/images/0611/16549249720005d72.png" style="width: 100%;" />
      </div>
      <div class="spaekifs m-b-15">
        <div class="m-b-15 spaekifs-title">
          <img src="https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2FSPARKLES.png" class="sparkies-img" />
          <div class="f-20">活动亮点</div>
        </div>
        <div class="spaekifs-wrap flex between">
          <div class="item m-b-15">
            <div class="title m-b-15 colorful">1 • 行业盛会 顶级规格</div>
            <div class="des">
              汇聚巨头公司、区块链科技巨擘、法律界权威人士、头部品牌IP、藏品平台、先锋艺术家等数藏行业全生态参与方，打造一场行业“去芜存菁”，对现状以及未来探讨的高规格行业盛会。
            </div>
          </div>
          <div class="item m-b-15">
            <div class="title m-b-15 colorful">2 • 权威报告 重磅发布</div>
            <div class="des">
              火讯财经·数藏之家联合十余家权威机构，成立“观火文化数字化产业智库”，重磅发布《2022上半年全球NFT数字藏品市场发展研究报告》，《数藏之家琅琊榜：2022上半年数藏平台100强》榜单，捕捉市场发展动态，洞悉行业发展方向。
            </div>
          </div>
          <div class="item m-b-15">
            <div class="title m-b-15 colorful">3 • 内容硬核 聚焦生态</div>
            <div class="des">
              Web3.0新浪潮？巨头抢跑？数藏合规？垂类探索？存量竞争？NFT营销？无聊猿？<br>
              在行业的“浪潮呼啸”与“烈火燎原”中，“深”、“精”、“广”、“透”，一次聊够！
            </div>
          </div>
          <div class="item m-b-15">
            <div class="title m-b-15 colorful">4 • 玩法精彩 福利丰厚</div>
            <div class="des">
              峰会预热，提前观火！<br>
              6月7~13日，开启数藏之家首档直播节目《心里有“数”》，一起“拷问”行业精英，直面顶流平台，剖析热销藏品。让每一个数藏用户心里有数，成为数字藏品行业的明白人。
              <br>节目和峰会期间，十几家藏品平台空投，数字藏品优先购福利派送不断！内容满满，福利满满！
            </div>
          </div>
        </div>
      </div>
      <div class="guest title-bg">
        <img style="width: 100%;" src="https://zhuzi.shucang123.cn.vc/uploads/images/0614/1655199070000ce3b.png" alt="">
      </div>
      <div class="activity-liucheng title-bg">
        <img class="full-width" src="https://zhuzi.shucang123.cn.vc/uploads/images/0614/1655199091000fbb6.png" />
      </div>
      <div class="live-zhichi title-bg">
        <img class="full-width" src="https://zhuzi.shucang123.cn.vc/uploads/images/0611/1654925038000b8f9.png" />
      </div>
      <div class="medium">
        <div class="zhuliu-media title-bg">
          <img class="full-width" src="https://zhuzi.shucang123.cn.vc/uploads/images/0611/16549250510002279.png" />
        </div>
        <div class="hangye-media title-bg">
          <img class="full-width" src="https://zhuzi.shucang123.cn.vc/uploads/images/0611/165492500500084d1.png" />
        </div>
      </div>
      <div class="cooperation flex m-t-15">
        <div class="button" @click="isShowCooperation = true">合作咨询</div>
      </div>
      <div class="bottom">
        <!-- <div class="f-15 colorful text-center bold">玩法精彩 福利丰厚</div>
            <div class="f-11 bottom-des text-center">
                6月14日号活动当天，开启【提前观火】活动
                众多数藏平台、优质IP藏品空投、福利不断
                扫码进群，参与活动！
            </div> -->
        <div class="flex between  btns">
          <div class="button f-16" @click="scan">
            领取邀请函
          </div>

          <div class="button f-16" @click="addGroup">
            扫码入群
          </div>
        </div>
        <div class="text-center m-b-15 f-11">领取邀请函,参与直播抽奖</div>
      </div>
    </div>

    <!-- 预约弹窗 -->
    <overly :show="isShowReserve" @close="isShowReserve = false">
      <div class="custom-overly">
        <div class="f-20 text-center m-b-15">预约提醒</div>
        <div class="f-15 text-center purple m-b-15">峰会开播前将给您短信提醒</div>
        <div class="text-center input-border flex">
          <input type="number" oninput="if(value.length>11) value=value.slice(0,11)" maxlength="11"
            v-model="user.mobile" placeholder="请输入您的手机号 " readOnly />
        </div>
        <div class="button reserve" @click="reserve()">预约观火</div>
      </div>
    </overly>
    <!-- 预约成功 -->
    <overly :show="isShowReserveSuccess" @close="isShowReserveSuccess = false">
      <div class="custom-overly">
        <div class="f-20 text-center m-b-15">预约成功</div>
        <div class="f-15 text-center purple m-b-15">欢迎加入峰会交流群</div>
        <div class="text-center m-b-15">
          <img :src="globalData.subscribe_img" class="code" />
        </div>
        <div class="purple text-center f-13">长按直接识别或保存二维码</div>
      </div>
    </overly>
    <!-- 加入峰会群 -->
    <van-overlay :show="isShowGroup" @click="isShowGroup = false" z-index="100"
      :custom-style="{ 'background': 'rgba(0, 0, 0, 0.9)' }">
      <div class="wrapper">
        <div class="block scan text-center">
          <img src="https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Flogo2.png" class="logo2" />
          <p class="f-15 text-center text bold">2022观火元宇宙数字藏品峰会</p>
          <img :src="globalData.subscribe_img" class="group" />
          <p class="f-15 text-center ">欢迎进峰会交流群</p>
        </div>
      </div>
    </van-overlay>
    <!-- 展示海报 -->
    <overly :show="isShowLinQu" @close="isShowLinQu = false">
      <div class="custom-overly">
        <div class="f-20 text-center m-b-15">领取邀请函</div>
        <div class="f-15 text-center purple m-b-15">请输入您的称呼以生成邀请函</div>
        <div class="text-center input-border flex">
          <input maxlength="6" v-model="nickname" placeholder="输入内容不可超过6个字 " />
        </div>
        <div class="button reserve" @click="showPoster">生成专属邀请函</div>
        <div class="text-center f-11">感谢对观火峰会的关注和支持</div>
        <div class="text-center f-11" style="padding:0 15px">峰会直播期间，将在拥有邀请函的用户中抽取多轮空投大奖。</div>
      </div>
    </overly>
    <!-- 合作咨询 -->
    <van-overlay :show="isShowCooperation" @click="isShowCooperation = false" z-index="100"
      :custom-style="{ 'background': 'rgba(0, 0, 0, 0.9)' }">
      <div class="wrapper">
        <div class="block scan text-center">
          <div class="text-center m-b-15">
            <img src="../assets/image/logo2.png" class="logo2" />
          </div>
          <img :src="globalData.cooperation_img" class="group" />
          <p class="f-15 text-center ">合作&参会咨询</p>
        </div>
      </div>
    </van-overlay>
    <!-- 展示海报 -->
    <van-overlay :show="isShowPoster" @click="isShowPoster = false" z-index="100"
      :custom-style="{ 'background': 'rgba(0, 0, 0, 0.9)' }">
      <div class="wrapper">
        <div class="block poster">
          <div class="real-poster">
            <img :src="sharePoster" v-if="sharePoster" style="width: 100%;height: 100%;" />
            <div class="empty flex" v-else>
              <van-loading>海报生成中...</van-loading>
            </div>
          </div>
          <div class="create-poster">
            <img src="https://zhuzi.shucang123.cn.vc/uploads/images/0610/16548557660004786.jpg"
              style="height: 100%;width:100%;" />
            <div class="text">{{ nickname }}</div>
            <vue-qr :text="url" class="qr-code" :margin="3" :size="53"></vue-qr>
          </div>
          <div class="text-center f-13 m-t-15 m-b-15">长按保存图片</div>
        </div>
      </div>
    </van-overlay>
    <Login-popover :show="isShowLogin" :type="1" @close="isShowLogin = false"></Login-popover>
  </div>
</template>
<script>
import {
  Toast,
  Dialog
} from 'vant';
import overly from '../components/overly'
import LoginPopover from '../components/Login'
import vueQr from 'vue-qr';
import html2canvas from 'html2canvas';
import {
  liveList,
  subscribe,
  global,
  sign,
  pic
} from '@/api/index'
import {
  mapState
} from 'vuex'
import {
  getWxConfig
} from '../plugins/weiShare.js'
import videojs from "video.js";
import "videojs-contrib-hls";
// import CryptoJS from './node_modules/crypto-jS'

export default {
  components: {
    overly,
    vueQr,
    LoginPopover
  },
  data() {
    return {
      moblie: '',
      isShowReserve: false,
      isShowReserveSuccess: false,
      isShowGroup: false,
      isShowLinQu: false,
      isShowCooperation: false,
      isShowPoster: false,
      isShowLogin: false,
      dataList: [],
      globalData: {}, //全局图片配置
      signNum: '',
      liveList: [], //直播列表
      liveSrc: '', //直播地址
      poster: '',
      overPoster: '',
      nickname: '',
      showPopover: false,
      sharePoster: '',
      actions: [{
        text: '背景介绍',
        el: '.introduce'
      },
      {
        text: '活动亮点',
        el: '.spaekifs'
      },
      {
        text: '出席嘉宾',
        el: '.guest'
      },
      {
        text: '合作咨询',
        el: '.cooperation'
      },
      {
        text: '活动流程',
        el: '.activity-liucheng'
      },
      {
        text: '直播机构',
        el: '.live-zhichi'
      },
      {
        text: '合作媒体',
        el: '.medium'
      },
      ],
      url: window.location.href,
      timer: null,
      numberTimer: null,
      audience: 0,
      scrollTop: 0,
      reviewUrl: ''
    }
  },
  computed: {
    ...mapState({
      token: state => state.token,
      user: state => state.user
    })
  },
  created() {
    getWxConfig()
  },
  mounted() {
    this.$nextTick(() => {
      this.overLive()
      this.getLiveList()
      global().then((res) => {
        this.globalData = res.data
      });
    })
    window.addEventListener('scroll', () => {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    })
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.numberTimer)
  },
  methods: {
    clear() {
      localStorage.clear()
      window.location.reload()
    },
    //预约
    reserve() {
      sign().then((res) => {
        this.signNum = res.data.secret
        let mobils = this.toHex(this.user.mobile)
        // let datasin = this.onSigns(mobils)
        let data = {
          id: this.liveId,
          mobile: mobils
        }
        subscribe(Object.assign(data, {
          sign: this.onSigns(data)
        })).then((res) => {
          this.isShowReserveSuccess = true
          this.isShowReserve = false
          this.getLiveList()
        }).catch((err) => {
          this.isShowReserve = false
          Toast.fail({
            message: err.message,
            forbidClick: true,
          });
          console.log(err)
        });
      }).catch((err) => {

      })
    },
    getLiveList() {
      liveList().then((res) => {
        this.liveList = res.message
        const nowTime = Math.ceil(Date.now() / 1000)
        let dataList = []
        const array = res.message.filter(item => item.id == 1)
        this.audience = array[0].live_num
        this.liveList.forEach((item, index) => {
          dataList.push({
            ...item,
            typeName: '',
            type: ''
          })
          if (nowTime < item.start_time) {
            dataList[index].typeName = '即将开始'
            dataList[index].type = 1
            this.poster = item.avatar
          } else if (nowTime > item.start_time && nowTime < item.end_time) {
            dataList[index].typeName = '进行中'
            dataList[index].type = 2
            const length = item.live_url.length
            if (length) {
              const random = Math.floor(length * Math.random())
              this.liveSrc = item.live_url[random]
              var myPlayer = videojs("myvideo");
              myPlayer.src([{
                type: "application/x-mpegURL",
                src: this.liveSrc
              }]);
              myPlayer.play();
            }
            this.getAudience()
          } else if (nowTime > item.end_time && !item.relive_url.length) {
            dataList[index].typeName = '等待回放'
            dataList[index].type = 3
          } else if (nowTime > item.end_time && item.relive_url.length) {
            dataList[index].typeName = '查看回放'
            dataList[index].type = 4
          }
        })
        this.liveList = dataList
        this.reviewUrl = dataList[0].relive_url[0]

      }).catch(err => {
        console.log(err)
        alert(JSON.stringify(err))
      });
    },
    overLive() {
      const now = new Date().getTime()
      const endTime = 1655208000000
      if (this.getOverPoster(now, endTime)) {
        this.initVideo()
        this.timer = setInterval(() => {
          const now = new Date().getTime()
          if (!this.getOverPoster(now, endTime)) {
            clearInterval(this.timer)
          }
        }, 1000)
      }
    },
    getOverPoster(now, endTime) {
      if (now - endTime >= 0) {
        liveList().then(res => {
          const array = res.message.filter(item => item.id == 1)
          this.overPoster = array[0].live_img
        }).catch(err => {
          console.log(err)
        })
      } else {
        return true
      }
    },
    getAudience() {
      this.numberTimer = setInterval(() => {
        liveList().then(res => {
          const array = res.message.filter(item => item.id == 1)
          this.audience = array[0].live_num
        }).catch(err => {
          console.log(err)
        })
      }, 1000 * 60)
    },
    showPoster() {
      if (!this.nickname) {
        Toast.fail({
          message: '请输入昵称',
          forbidClick: true,
        });
        return
      }
      this.isShowPoster = true
      this.isShowLinQu = false
      pic()
      setTimeout(() => {
        this.createPoster()
      }, 1000);
    },
    onSigns(data = {}) {
      let sign = {}
      let keys = Object.keys(data);
      keys.sort();
      keys.forEach((key) => {
        sign[key] = data[key];
      })
      sign = JSON.stringify(sign)
      console.log('json:', sign)
      sign = this.onGetEncode64(sign)
      console.log('base64:', sign)
      let key = this.signNum;
      sign = this.$CryptoJS.HmacSHA256(sign, key)
      sign = this.$CryptoJS.enc.Hex.stringify(sign);
      console.log('hash:', sign)
      return sign.toUpperCase()
    },
    onGetEncode64(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }));
    },
    toHex(num) {
      var rs = "";
      var temp;
      while (num / 16 > 0) {
        temp = num % 16;
        rs = (temp + "").replace("10", "a").replace("11", "b").replace("12", "c").replace("13", "d").replace("14",
          "e").replace("15", "f") + rs;
        num = parseInt(num / 16);
      }
      rs = 'djkssd' + rs + 'hjhhss'
      console.log('rs', rs)
      return rs;
    },
    onGoto(item, index) {
      item.type == 4 && item.relive_url ?
        window.location.href = item.relive_url[0] :
        '';
    },
    goTo() {
      window.location.href = this.reviewUrl
    },
    onSelect(item) {
      console.log(item)
      const element = document.querySelector(item.el)
      if (element) {
        element.scrollIntoView({
          behavior: 'auto'
        });
      }
    },
    initVideo() {
      videojs(
        "myvideo", {
        bigPlayButton: true,
        textTrackDisplay: false,
        posterImage: true,
        errorDisplay: false,
        controlBar: true
      },
        function onPlayerReady() {
          this.on("error", function () { })
        }
      );

    },
    createPoster() {
      const dom = document.querySelector('.create-poster')
      html2canvas(dom, {
        width: dom.getBoundingClientRect().width,
        height: dom.getBoundingClientRect().height - 1,
        backgroundColor: null, // null 表示设置背景为透明色
        useCORS: true, // 是否尝试使用CORS从服务器加载图像
        allowTaint: true, // 允许跨域（图片跨域相关），服务器也需要做相应的图片跨域处理
        taintTest: true, // 是否在渲染前测试图片
        scale: 3, // dpr比列
        scrollY: 0 // 截屏时页面滚动，造成截屏图片不全或空白
      }).then(canvas => {
        const img = canvas.toDataURL('image/png')
        this.sharePoster = img
      }).catch(e => {
        console.log(e)
        this.$toast.fail('生成海报失败')
      })
    },
    readyReserve(id) {
      if (this.token) {
        this.isShowReserve = true
        this.liveId = id
      } else {
        this.isShowLogin = true
      }
    },
    scan() {
      Dialog({
        message: '活动已结束' ,
        confirmButtonColor: '#2C65E8'
        });
      return
      if (this.token) {
        this.isShowLinQu = true
      } else {
        this.isShowLogin = true
      }
    },
    addGroup() {
      Dialog({
        message: '活动已结束' ,
        confirmButtonColor: '#2C65E8'
        });
      return
      this.isShowGroup = true
    }
  }
}
</script>
<style lang="scss" scoped>
.code {
  width: 120px;
  height: 120px;
}

.index {
  background-color: black;
  color: #ffffff;
  background-color: black;

  // -webkit-overflow-scrolling: touch;
  img {
    pointer-events: none;
  }

  .up {
    background: url(https://zhuzi.shucang123.cn.vc/uploads/images/0610/16548556490008b7d.png) no-repeat;
    background-size: 100%;
    padding: 60px 10px 15px;
    -webkit-overflow-scrolling: touch;

  }

  .up>* {
    -webkit-transform: translateZ(0px);
  }

  .down {
    padding: 15px 10px;
    -webkit-overflow-scrolling: touch;
  }

  .down>* {
    -webkit-transform: translateZ(0px);
  }

  .grey {
    opacity: 0.5;
  }

  .purple {
    color: #817DE6;
  }

  .colorful {
    background: linear-gradient(90deg, #2C65E8 10%, #D99BED 26.681641%, #6867F6 73.321289%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
    padding: 15px 10px 10px;
    margin: auto;
    box-sizing: border-box;
    max-width: 476px;
    .logo {
      height: 40px;
      width: 167px;
    }
  }

  .video {
    margin-top: 18px;
    background: url(https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fvideo-bg2.png) no-repeat;
    background-size: 100% 100%;
    height: 217px;
    box-sizing: border-box;
    padding: 5px 4px 19px 4px;
    position: relative;

    .video-tip {
      position: absolute;
      right: 10px;
      top: 8px;
      z-index: 2;
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .video-tip2 {
      position: absolute;
      left: 10px;
      top: 8px;
      z-index: 2;
      background: linear-gradient(90deg, #6717CD, #2871FA);
      border-radius: 8px;
      padding: 2px 15px;
      font-size: 9px;

      &::after {
        position: absolute;
        left: 8px;
        content: '';
        width: 4px;
        height: 4px;
        background: #FFD161;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    #myvideo {
      width: 100%;
      height: 100%;
      border-radius: 7px;
    }
  }

  .introduce {
    margin-top: 20px;

    .background {
      width: 149px;
      height: 15px;
    }

    .background-wrap {
      background: url(https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fintroduce-wrap.png) no-repeat;
      background-size: 100% 100%;
      margin-top: -14px;
      line-height: 17px;
      text-align: justify;
      position: relative;
      padding: 50px 10px 20px;

      .background-wrap-des {
        padding: 16px 15px;
        background: url(https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fintroduce-wrap-des.png) no-repeat;
        background-size: 100% 100%;
        margin-bottom: 10px;

      }

      .zhi-bo {
        position: absolute;
        font-style: italic;
        top: 8px;
        right: 40px;
      }
    }
  }

  .activies {
    .activies {
      background-size: 100% 100% !important;
      padding: 20px 15px;
      margin-bottom: 10px;

      .review {
        width: 100px;
        height: 40px;
        line-height: 35px;
        background-position-y: -2px;
      }
    }

    .reserve-btn {
      width: 80px;
      height: 18px;
      line-height: 20px;
      display: inline-block;
      background: linear-gradient(91deg, #6717cd, #2871fa);
      color: #ffffff;
      text-align: center;
      // clip-path: polygon(10px 0,
      //         calc(100% - 10px) 0,
      //         100% 0px,
      //         100% 0px,
      //         calc(100% - 0px) 100%,
      //         8px 100%,
      //         0 calc(100% - 0px),
      //         0 8px);
      clip-path: polygon(10px 0, 100% 0, 100% 100%, 0 100%, 0 45%);
      font-style: italic;
    }

    .ing {
      color: #0AFF55;
      position: relative;
      padding-left: 10px;

      &::after {
        width: 4px;
        height: 4px;
        background: #0AFF55;
        border-radius: 50%;
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .fire {
    e .fire-icon {
      width: 100%;
      height: 130px;
    }
  }

  .spaekifs {
    .spaekifs-title {
      padding: 10px;
    }

    .sparkies-img {
      width: 110px;
      height: 15px;
    }

    .spaekifs-wrap {
      flex-wrap: wrap;

      .item {
        width: 100%;
        background: #111111;
        padding: 20px 10px;

        .title {
          text-align: left;
          font-size: 13px;
          font-weight: bold;
        }

        .des {
          color: #808080;
          line-height: 16px;
          text-align: justify;
          font-size: 10px;
        }
      }
    }
  }

  .guest {
    background: url(https://zhuzi.shucang123.cn.vc/uploads/images/0610/16548557040003582.png) no-repeat;
    background-size: contain;
    margin-bottom: 50px;
  }

  .medium {
    background: url(https://zhuzi.shucang123.cn.vc/uploads/images/0610/16548557420002fb0.png) no-repeat;
    background-size: contain;
    background-position-y: bottom;
  }

  .cooperation {
    justify-content: center;
    margin-bottom: 80px;
  }

  .title-bg {
    background: url(https://zhuzi.shucang123.cn.vc/uploads/images/0611/16549248980003218.png) no-repeat;
    background-size: 100%;
    margin-bottom: 50px;
  }

  .button {
    width: 50%;
    height: 67px;
    background: url(https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fbutton.png) no-repeat;
    background-size: 100% 120%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    box-sizing: border-box;
    max-width: 476px;
    margin: auto;
    .bottom-des {
      line-height: 19px;
      padding: 15px 50px;
    }

    .btns {
      justify-content: center;
    }
  }

  .custom-overly {
    padding: 60px 20px;

    .input-border {
      margin: 35px 25px 5px;
      height: 38px;
      background: url(https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Finput-border.png) no-repeat;
      background-size: 100%;
      justify-content: center;

      input {
        background-color: transparent;
        outline: none;
        border: none;
        padding: 0 20px;
        width: 100%;
        font-size: 13px;
        text-align: center;

        &::placeholder {
          color: #3F3C7C;
          text-align: center;
        }
      }
    }
  }

  .reserve {
    width: 76%;
    height: 76px;
    margin: auto;

    .code {
      width: 127px;
      height: 127px;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .block {
      width: 310px;
      box-sizing: border-box;

      img {
        pointer-events: unset;
      }
    }

    .scan {
      .logo2 {
        width: 140px;
        height: 122px;
      }

      .text {
        background: linear-gradient(91deg, #D99BED 0%, #6867F6 56.005859375%, #2C65E8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .group {
        width: 147px;
        height: 147px;
      }
    }

    .poster {
      background-color: #222222;
      position: relative;
      width: 280px;

      .real-poster {
        height: 606px;

        .empty {
          height: 100%;
          justify-content: center;
        }
      }

      .create-poster {
        position: absolute;
        top: -10000px;
        height: 606px;
        width: 100%;
        background-size: 100% 100%;
        // z-index: 10;

        .text {
          position: absolute;
          left: 15px;
          top: 50%;
          font-size: 27px;
        }

        .qr-code {
          position: absolute;
          right: 15px;
          bottom: 10px;
        }
      }
    }
  }
}
</style>
