<template>
    <van-overlay z-index="100" :show="show" @click="close" :custom-style="{ 'background': 'rgba(0, 0, 0, 0.9)' }">
        <div class="wrapper" @click.stop>
            <div class="block">
                <slot></slot>
                <img src="https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fclose.png" class="close" @click="close" />
            </div>
        </div>
    </van-overlay>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .block {
        width: 310px;
        background: url(https://zhuzi.shucang123.cn.vc/uploads/images/0610/16548557530007d1f.png) no-repeat;
        background-size: 100%;
        height: 388px;
        box-sizing: border-box;
        position: relative;

        img {
            pointer-events: unset;
        }

        .close {
            position: absolute;
            widows: 30px;
            height: 30px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
</style>