import { wxConfig } from '../api/index.js'
import wx from 'weixin-js-sdk'

export const getWxConfig = async (title, desc) => {
  const ua = navigator.userAgent.toLowerCase()
  const isWeixin = ua.indexOf('micromessenger') !== -1
  if (!isWeixin) {
    return
  }
  try {
    const res = await wxConfig({
      url: window.location.href,
      js: ['onMenuShareAppMessage', 'onMenuShareTimeline']
    })
    console.log("adadadada  ",res)
    wx.config({
      debug: false, // 开启调试模式
      appId: res.data.appid, // 必填，公众号的唯一标识
      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      signature: res.data.signature, // 必填，签名
      jsApiList: [
        'onMenuShareAppMessage', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        'onMenuShareTimeline' // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
      ]
    })
  } catch (e) {
    console.log(e)
  }
  wx.ready(function () { // 需在用户可能点击分享按钮前就先调用
    wx.onMenuShareAppMessage({
      title: title || '2022观火元宇宙数字藏品峰会', // 分享标题
      desc: desc || '点击链接观看峰会直播，观元宇宙、数藏行业之火。',
      // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      link: window.location.href.split('#')[0]+'#'+window.location.href.split('#')[1],
      imgUrl: 'https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Flogo2.png', // 分享图标
      success: function () {
        // 设置成功
      }
    })
    wx.onMenuShareTimeline({
      title: title || '2022观火元宇宙数字藏品峰会', // 分享标题
      // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      link: window.location.href.split('#')[0]+'#'+window.location.href.split('#')[1],
      imgUrl: 'https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Flogo2.png',
      success: function () {
        // 设置成功
      }
    })
  })
  wx.error(function (res) {
  })
}