import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CryptoJS from 'crypto-js'
import './assets/css/basic.css'
import './plugins/vant'
import 'video.js/dist/video-js.css'
import Clipboard from 'clipboard';

Vue.prototype.Clipboard = Clipboard
Vue.config.productionTip = false
Vue.prototype.$CryptoJS = CryptoJS

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
