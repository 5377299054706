<template>
    <van-overlay :show="show" @click="close" :custom-style="{'background':'rgba(0, 0, 0, 0.7)'}" z-index="100">
        <div class="wrapper" @click.stop>
            <div class="block">
                <div class="bg">
                    <img :src="type ? 'https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fbg.png' : 'https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fbg-red.png'" style="width: 100%;height:100%;"/>
                </div>
                <div class="flex center title">
                    <img class="logo" src="https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Flogo-red.png"/>
                    <div class="f-18">{{title}}</div>
                </div>
                <slot></slot>
                <img :src="type ? 'https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fclose.png' : 'https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fclose-red.png'" class="close" @click="close"/>
            </div>
        </div>
    </van-overlay>
</template>
<script>
export default{
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        type: {
            type: Number, // 1.表示直播
            default: 0
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: scroll;
    .block {
        width: 326px;
        // background: url(../assets/image/login/bg.png) no-repeat;
        // background-size: 100%;
        height: 431px;
        box-sizing: border-box;
        position: relative;
        padding: 40px 24px 24px;
        .bg{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            opacity: 0.9;
        }
        .logo{
            width: 23px;
            height: 27px;
            padding-right: 8px;
        }
        .title{
            margin-bottom: 28px;
        }
        .close{
            position: absolute;
            widows: 30px;
            height: 30px;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
</style>