import http from './http'

//直播列表
export const liveList = (data) =>{
    return http.get(`live/`, {
        params: data
    })
}

//预约直播
export const subscribe = (data) =>{
    return http.post(`nft/live/subscribe`, data)
}

//获取全局配置
export const global = (data) =>{
    return http.get(`nft/config/global`, {
        params: data
    })
}


//获取sign验签的secret
export const sign = (data) =>{
    return http.get(`nft/config/sign`, {
        params: data
    })
}

//获取微信配置
export const wxConfig = (data) =>{
    return http.post(`/nft/config/wx`, data)
}


//获得图像验证码
export const imgCode = (data) =>{
    return http.get(`nft/send_code/captcha`, {
        params: data
    })
}

//获得短信验证码
export const msgCode = (data) =>{
    return http.get(`nft/send_code/login`, {
        params: data
    })
}

//登录
export const loginApi = (data) =>{
    return http.post(`nft/login/signIn`, data)
}

//抽签
export const pic = (data) => {
  return http.get(`nft/live/pic`, {
    params: data
  })
}
