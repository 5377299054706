<template>
    <div class="my-input flex" :class="status === '-input' ? 'input-border' : ''">
        <img :src="require('../assets/image/login/'+ type + status + '.png')" class="icon"/>
        <input :value="value" :placeholder="placeholder" :maxlength="maxlength" @input="change"
        @focus="status = '-input'" @blur="status = ''" :type="inputType"/>
    </div>
</template>
<script>
export default {
    modal: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: {
            type: String,
        },
        placeholder: {
            type: String
        },
        type: {
            type: String,
            default() {
                return 'tel'
            }
        },
        maxlength: {
           type: [String, Number]
        },
        inputType: {
            type: String,
            default() {
                return 'text'
            }
        }
    },
    data() {
        return{
            status: '',
        }
    },
    methods: {
        change(value) {
            this.$emit('input', value.target.value)
        }
    }
}
</script>
<style lang="scss" scoped>
.my-input {
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: rgba(255, 255, 255, 0.08);
    padding: 0 12px;
    .icon{
        width: 20px;
        height: 20px;
    }
    input {
        background: transparent;
        padding: 12px 8px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.87);
        border: none;

        &::placeholder {
            color: rgba(255, 255, 255, 0.4);
        }
    }
}
.input-border{
    border-image: linear-gradient(180deg, rgba(255, 76, 70, 1), rgba(170, 64, 222, 1)) 1 1;
}
</style>