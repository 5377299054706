<template>
    <login-over-vue :show="show" title="预约峰会直播" class="login" @close="close" :type="type">
        <custom-input class="m-b-12" v-model="mobile" @input="mobileInput" :maxlength="11" inputType="number" placeholder="请输入手机号">
        </custom-input>
        <div class="flex between m-b-12">
            <custom-input style="width: 150px;" type="code" v-model="code" :maxlength="4" placeholder="请输入4位验证码">
            </custom-input>
            <div class="code-img" @click="refresh">
                <img :src="baseURL + 'nft/send_code/captcha?key=' + randomString" style="width: 100%;height:100%;" />
            </div>
        </div>
        <div class="three">
            <custom-input type="code" v-model="msgCode" :maxlength="4" placeholder="请输入验证码"></custom-input>
            <span class="f-14 msg-code" v-show="isShowTime">{{ time }}s</span>
            <span class="f-14 msg-code" v-show="!isShowTime" @click="getMsgCode">获取验证码</span>
        </div>
        <div class="flex center">
            <div class="custom-button flex center f-16" v-show="disabled" @click="login">预约</div>
            <div class="custom-button flex center f-16 disabled" v-show="!disabled">预约</div>
            <!-- <van-button :color="bgColor" class="button"  :disabled="!disabled">预约</van-button> -->
        </div>
        <!-- <div class="text-center" style="opacity: 0.5;">未注册账号将自动注册</div> -->
    </login-over-vue>
</template>
<script>
import loginOverVue from "./loginOver.vue";
import customInputVue from "./customInput.vue";
import CustomInput from "./customInput.vue";
import { msgCode, loginApi } from "@/api";
import { getRandomString, phoneEncode } from "@/plugins/tool";
import { baseURL } from '@/api/http'
import { Toast } from "vant";
export default {
    components: { loginOverVue, customInputVue, CustomInput },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        type: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            mobile: '',
            code: '',
            msgCode: '',
            isShowTime: false,
            time: 60,
            timer: null,
            randomString: getRandomString(),
            baseURL: baseURL
        }
    },
    computed: {
        disabled() {
            return this.mobile && this.code && this.msgCode ? true : false
        }
    },
    methods: {
        async login() {
            try {
                const res = await loginApi({
                    mobile: phoneEncode(this.mobile),
                    code: this.msgCode,
                    from_uid: localStorage.getItem('invited_id')
                })
                this.$store.commit('setToken', res.data.acctoken)
                this.$store.commit('setUser', res.data.user)
                localStorage.setItem('user_id', res.data.user.id)
                const origin = window.location.origin
                const hash = window.location.hash
                const pathname = window.location.pathname
                this.close()
                window.location.href = `${origin}${pathname}?invited_id=${res.data.user.id}${hash}`
            } catch (error) {
                console.log(error)
                Toast.fail({
                    message: error.message,
                    forbidClick: true
                })
            }
        },
        refresh() {
            this.randomString = getRandomString()
        },
        close() {
            this.$emit('close')
        },
        async getMsgCode() {
            if (!this.mobile) {
                Toast.fail({
                    message: '请填写手机号码',
                    forbidClick: true,
                })
                return
            }
            if (!this.code) {
                Toast.fail({
                    message: '请填写图形验证码',
                    forbidClick: true,
                })
                return
            }
            try {
                const res = await msgCode({
                    mobile: phoneEncode(this.mobile),
                    key: this.randomString,
                    code: this.code
                })
                this.countDown()
                Toast.success({
                    message: res.message,
                    forbidClick: true
                })
            } catch (error) {
                console.log(error)
                Toast.fail({
                    message: error.message,
                    forbidClick: true
                })
            }
        },
        countDown() {
            this.isShowTime = true
            clearInterval(this.timer)
            this.timer = setInterval(() => {
                this.time--
                if (this.time <= 0) {
                    clearInterval(this.timer)
                    this.isShowTime = false
                }
            }, 1000)
        },
        mobileInput(value) {
            console.log(value.slice(0,11))
            this.mobile = value.slice(0,11)
        }
    }
}
</script>
<style lang="scss" scoped>
.login {
    .code-img {
        width: 103px;
        height: 42px;
        background: rgba(255, 255, 255, 0.08);
        margin-left: 10px;
    }

    .m-b-12 {
        margin-bottom: 12px;
    }

    .three {
        position: relative;

        .msg-code {
            position: absolute;
            color: rgba(106, 248, 255, 0.5);
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
        }
    }

    .custom-button {
        margin: 60px auto 20px;
        width: 186px;
        height: 51px;
        background: url(https://zhuzi.shucang123.cn.vc/uploads%2Fimages%2F0610%2Fbutton-red.png) no-repeat;
        background-size: 100% 100%;
    }
    .disabled{
        filter: grayscale(1);
        color: rgba(255, 255, 255, 0.6);
    }
}
</style>